<template>
  <b-modal
    hide-footer
    no-enforce-focus
    ref="formModal"
    id="driver-profilesCreateModal"
    title="Добавить анкету"
    size="md"
    @show="getData()"
    @close="closeModal()"
    @shown="detectStartTime"
    @hidden="detectEndTime"
  >
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <label for=""> Подразделение</label>
          <v-select
            v-model="form.division_id"
            placeholder="Подразделение"
            :reduce="(option) => option.id"
            label="name"
            :options="divisions"
            class="select-size-md"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="4">
          <label for="">Имя</label>
          <b-input
            type="text"
            placeholder="Имя"
            v-model="form.first_name"
          ></b-input>
        </b-col>
        <b-col cols="4">
          <label for=""> Фамилия</label>
          <b-input
            type="text"
            placeholder="Фамилия"
            v-model="form.last_name"
          ></b-input>
        </b-col>
        <b-col cols="4">
          <label for="">Отчество</label>
          <b-input
            type="text"
            placeholder="Отчество"
            v-model="form.patronymic"
          ></b-input>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="6">
          <label for=""> Телефон</label>
          <b-input
            type="text"
            placeholder="992927777770"
            v-model="form.phone"
          ></b-input>
        </b-col>
        <b-col cols="6">
          <label for="">Доп. телефон</label>
          <b-input
            type="text"
            placeholder="992927777770"
            v-model="form.dop_phone"
          ></b-input>
        </b-col>
      </b-row>
      <hr />
      <h5 class="mt-1">Время, удобное для звонка</h5>
      <b-row>
        <b-col cols="6">
          <label for="">С</label>
          <b-input type="time" v-model="form.from_time"></b-input>
        </b-col>
        <b-col>
          <label for="">До </label>
          <b-input type="time" v-model="form.before_time"></b-input>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12">
          <label for="">Комментарий</label>
          <b-textarea v-model="form.comment"></b-textarea>
        </b-col>
      </b-row>
    </b-col>
    <div class="d-flex justify-content-end">
      <b-col class="mt-1">
        <div>
          {{ dateTime.hours }}:{{
            dateTime.minutes < 10 ? "0" + dateTime.minutes : dateTime.minutes
          }}
        </div>
      </b-col>
      <b-button
        :disabled="submitButtonDisabled"
        class="mt-1 align-self-end mr-1"
        variant="primary"
        size="sm"
        @click="addForm()"
      >
        Ок
      </b-button>
      <b-button
        class="mt-1 align-self-end mr-1"
        variant="danger"
        size="sm"
        @click="closeModal()"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { eventBus } from "@/main.js";

const date = new Date();
export default {
  components: {
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      formTime: {
        form_name: null,
        start_time: null,
        end_time: null,
      },
      divisions: [],
      form: {
        division_id: "",
        type_id: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        phone: "",
        from_time: "",
        before_time: "",
        comment: "",
      },
      dataTime: null,
      incomeDivision: null,
      submitButtonDisabled: false,
      divisions: [],
      prompts: false,
      types: [],
      dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      },
    };
  },
  mounted() {
    this.dataTime = setInterval(this.setDateTime, 1000);
    eventBus.$on("sendInfo", (data) => {
      this.incomeDivision = Number(data.division_id);
      // set income division
      if (this.divisions.length) {
        this.form.division_id = Number(data.division_id);
      }
      // set income phone
      this.form.phone = data.phone || "";
    });
  },
  methods: {
    addForm() {
      if (this.form.patronymic == null || this.form.patronymic == "") {
        this.form.patronymic = undefined;
      }
      if (this.form.comment == null || this.form.comment == "") {
        this.form.comment = undefined;
      }
      if (this.form.from_time == null || this.form.from_time == "") {
        this.form.from_time = undefined;
      }
      if (this.form.before_time == null || this.form.before_time == "") {
        this.form.before_time = undefined;
      }
      this.submitButtonDisabled = true;
      this.$http
        .post("driver-profiles", this.form)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Анкета успешно добавлена!`,
            },
          });
          this.closeModal();
          this.$bvModal.hide("driver-profilesCreateModal");
          if (this.$route.name === "driver-profiles") {
            this.$emit("refresh");
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    detectEndTime() {
      this.formTime.end_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      this.$http
        .post("time-form", this.formTime)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detectStartTime() {
      this.formTime.form_name = "requestCreateForm";
      this.formTime.start_time = this.$moment().format("YYYY-MM-DD HH:mm:ss");
    },
    setDateTime() {
      const date = new Date();
      this.dateTime = {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      };
    },
    getData() {
      this.$http
        .get("drivers/data")
        .then((res) => {
          this.divisions = res.data.divisions;
          setTimeout(() => {
            this.form.division_id = this.incomeDivision || 0;
          }, 50);
        })
        .catch((err) => {});
      this.$http
        .get("driver-profile-types")
        .then((res) => {
          this.types = res.data;
        })
        .catch((err) => {});
    },
    closeModal() {
      this.form = {
        division_id: "",
        type_id: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        phone: "",
        from_time: "",
        before_time: "",
        comment: "",
      };
      this.$bvModal.hide("driver-profilesCreateModal");
    },
  },
  beforeDestroy() {
    if (this.dataTime) {
      clearInterval(this.dataTime);
    }
  },
};
</script>

<style scoped>
.prompt {
  position: fixed;
  left: 0;
  width: 300px;
  height: 100vh;
  top: 0;
  background-color: #fff;
}
</style>
